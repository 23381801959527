import React, { useEffect, useState } from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/BackgroundAsImageWithCenteredContent.js";
import Features from "components/features/VerticalWithAlternateImageAndText.js";
import Blog from "components/blogs/ThreeColSimpleWithImage.js";
import Testimonial from "components/testimonials/TwoColumnWithImage.js";
import ContactUsForm from "components/forms/SimpleContactUs.js";
import Footer from "components/footers/MainFooter";
import Activities from "components/cards/Activities.js";
import Programs from "components/cards/Programs";
import About from "components/cards/About";
import DYSLocations from "components/popup/DYSLocations";

export default (props) => {
  const [showValue, setShowValue] = useState(props.show || undefined)

  useEffect(() => {
    const url = new URL(window.location);
    const params = new URLSearchParams(url.search);
    params.get("show") && setShowValue(params.get("show"));
  }, [])

  return (
    <AnimationRevealPage>
      <Hero showValue= {showValue === 'dysLocations'}/>
      {!showValue && 
        <>
          <Programs />
          <Activities />
          <About actions={true}  headingLeftCss="true"/>
          <Footer />
        </>
      }
      {showValue === 'dysLocations' &&
          <DYSLocations showAsPage={true}/>
      }
    </AnimationRevealPage>
  )
};
