import React, { useEffect, useState } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/BackgroundAsImageWithCenteredContent.js";
import Testimonial from "components/testimonials/TwoColumnWithImage.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
// import Testimonial from "components/testimonials/SimplePrimaryBackground.js";
import Footer from "components/footers/MainFooter";
import TabWithoutImage from "components/cards/TabWithoutImage";
import About from "components/cards/About";
import PM from "images/testimonial/PranabMukherjee.jpg";
import SR from "images/testimonial/SiddharthaRastogi.jpg";
import AP from "images/testimonial/AjayPiramal.jpg";
import AustinR from "images/tieUps/AustinR.png";
import DYPU from "images/tieUps/DYPU.png";
import Prachetas from "images/tieUps/Pracheta.png";
import SITP from "images/tieUps/SITP.png";
import TataProjects from "images/tieUps/TataProjects.png";
import UnnatiF from "images/tieUps/UnnatiF.jpg";
import Westend from "images/tieUps/Westend.svg";
import ffl from "images/ffl.jpg";
import FoodForLifeHero from "components/hero/FoodForLifeHero";
import { ReactComponent as CheckboxIcon } from "feather-icons/dist/icons/check-circle.svg";
import YoutubeVideo from "components/cards/YoutubeVideo";
import FFLMetaPixelCode from "helpers/FFLMetaPixelCode";
import TieUps from "components/misc/TieUps";

const Subheading = tw.span`uppercase tracking-widest font-bold text-primary-500`;
const SubheadingInverse = tw.span`mt-2 uppercase tracking-widest block font-bold bg-primary-500 text-white px-3 py-2 rounded-md`;
const FeatureList = tw.ul`mt-5 leading-loose`;
const Feature = tw.li`flex items-start items-center uppercase text-black`;
const FeatureBox = tw.div`rounded-xl w-full h-40 text-center border-2 m-1 md:m-2 py-3 flex flex-col items-center border-black`;
const FeatureIcon = tw(CheckboxIcon)`w-3 md:w-5 h-3 md:h-5 mt-2 md:mt-0 text-primary-500 flex-shrink-0`;
const FeatureText = tw.p`ml-2 font-medium text-left`;
const Para = tw.p`leading-relaxed font-medium text-gray-700`;

const PrimaryButton = tw(PrimaryButtonBase)`mt-8 inline-block tracking-wide text-center py-3 bg-ffl-900 text-black`;
const BoxButton = tw(PrimaryButtonBase)`inline-block tracking-wide text-center px-1 text-lg w-3/4 bg-ffl-900 text-black py-2`;
const BoxText = tw.div`text-black uppercase font-bold text-xl md:text-base`;
const FeatureBoxText = tw.div`text-black uppercase font-bold text-base md:text-xl`;
const BoxTextAmount = tw.div`text-2xl md:text-3xl font-bold text-black uppercase`;

const BankDetails = tw.div`font-thin border-b border-black text-left px-10 py-1 pl-0 text-black`;
const TextPrimary = tw.span` font-bold text-black`;
const CopyButton = tw.button`md:text-sm text-xs px-2 ml-2 bg-primary-300 text-white rounded-full`;
const TableButton = tw(PrimaryButtonBase)`inline-block tracking-wide text-center px-2 md:px-4 py-1 md:text-sm text-xs bg-ffl-900 text-black`;

const RequestMessage = tw.div`p-4 bg-primary-900 text-white md:text-2xl font-thin items-center text-center -mx-8 py-10 lg:px-32`;
const Link = tw.a`text-ffl-900 hover:text-ffl-300`;

const testimonials = [
  {
    imageSrc:
      PM,
    quote:
      "(ISKCON) movement, Just imagine…within a short span of time… today I am told ISKCON movement runs more than 600 centres all over the world, everyday in India it provides food to over one million children…healthy food…and the message…of love, compassion, which is inherent in Indian civilization.",
    customerName: "Pranab Mukherjee",
    customerTitle: "Ex-President of India"
  },
  {
    imageSrc:
      SR,
    quote:
      "ISKCON Food for Life is doing a fantastic job. Just trying to capture my experience through these lines. An honest effort named ISKCON Food for Life, Endeavouring to eradicate hunger from a nation.",
    customerName: "Siddhartha Rastogi",
    customerTitle: "Director, Ambit Capital Private Limited"
  },
  {
    imageSrc:
      AP,
    quote:
      "I have been associated with ISKCON for many years now... Earlier the number of children coming to school was very low but now 96% of all the children are coming to school and a significant contribution to this is due to the meals of Food for Life.",
    customerName: "Ajay Piramal",
    customerTitle: "Chairman, Piramal Group"
  }
]

const tieUps = [
  {
    imageSrc: AustinR,
    altText: "Austin"
  },
  {
    imageSrc: DYPU,
    altText: "DY Patil University Pune"
  },
  {
    imageSrc: Prachetas,
    altText: "Pracheta"
  },
  {
    imageSrc: SITP,
    altText: "SIT, Pune"
  },
  {
    imageSrc: TataProjects,
    altText: "Tata Projects"
  },
  {
    imageSrc: UnnatiF,
    altText: "Unnati Foundation"
  },
  {
    imageSrc: Westend,
    altText: "Nexus Westend"
  }
]

const aboutffl = <div>
  Food for Life program is a project of the ISKCON BCEC. Food for Life is a strategic program to offer quality food to those affected from the scourge 
  <br />
  of Hunger. This is a humble attempt to serve for the cause of humanity. We intend to begin this new project in July 2023, with an aim to reach a
  <br />
  thousand meals every day & reach out to all without any discrimination on grounds of social, religious, caste, creed or gender.
  <br />
  <br />
  <span className="font-bold text-lg">Vision</span> 
  <br />
  No person shall be deprived of nutritious sanctified vegetarian diet due to poverty or lack of provision
  <br />
  <br/>
  <span className="font-bold text-lg">Mission</span> 
  <br />
  To feed 1,000 hungry persons every day by 2026
  <br />
  <br/>
  <span className="font-bold text-lg">We commit to..</span> 
  <br />
  Deliver best quality, nutritious, and sanctified food Dealing with transparency, warmth, dignity, and respect Bringing the members of society closer together for the purpose of serving the needy.
  <br />
  <div className="text-xs invisible md:visible">
  Proceeds for the Food For Life activities are covered 
  <br />
  under rules of Ministry of Corporate Affairs for CSR
  <br />
  Grant and are allowed to attract CSR grants.
  </div>
  <div className="text-xs visible md:invisible">
  Proceeds for the Food For Life activities are covered under rules of Ministry of Corporate Affairs for CSR Grant and are allowed to attract CSR grants.
  </div>
</div>;

export default () => {
  const buttonRoundedCss = tw`rounded-full`;

  const fflDonationTabs = {
    'Sponsor Meal': {
      right: {
        text:true,
        subheading: <SubheadingInverse>Donation Through Bank (NEFT/ RTGS)</SubheadingInverse>,
        heading: <></>,
        description:
          <>
            <BankDetails>
              Bank Name: <TextPrimary>HDFC Bank</TextPrimary><br/>
              Account Name: <TextPrimary>ISKCON BCEC FFL</TextPrimary>
              <CopyButton onClick={() => {navigator.clipboard.writeText("ISKCON BCEC FFL")}}>Copy</CopyButton>
              <br/>
              Account Number: <TextPrimary>50100611973595</TextPrimary>
              <CopyButton onClick={() => {navigator.clipboard.writeText("50100611973595")}}>Copy</CopyButton>
              <br/>
              IFSC Code: <TextPrimary>HDFC0000148</TextPrimary>
              <CopyButton onClick={() => {navigator.clipboard.writeText("HDFC0000148")}}>Copy</CopyButton>
            </BankDetails>
            <TextPrimary>80G available as per Income Tax Act 1961 and rules made there under</TextPrimary>
          </>,
        imageSrc: ffl,
        imageBorder: true,
        imageDecoratorBlob: true,
        showButton: false
      },
      left: {
        text:true,
        heading: <></>,
        description: <>
                  <div className="flex flex-col">
                    <div className="flex lg:flex-row flex-col">
                      <FeatureBox>
                        <BoxText>Sponsor 20 meals</BoxText>
                        <BoxTextAmount>₹ {20 * 50}</BoxTextAmount>
                        <BoxButton as="a" href={`https://giveffl.iskconpunebcec.com/give.php?amt=${20 * 50}&`} css={buttonRoundedCss} className="mt-2">
                          Sponsor
                        </BoxButton>
                      </FeatureBox>
                      <FeatureBox>
                        <BoxText>Sponsor 50 meals</BoxText>
                        <BoxTextAmount>₹ {50 * 50}</BoxTextAmount>
                        <BoxButton as="a" href={`https://giveffl.iskconpunebcec.com/give.php?amt=${50 * 50}&`} css={buttonRoundedCss} className="mt-2">
                          Sponsor
                        </BoxButton>
                      </FeatureBox>
                    </div>
                    <div className="flex lg:flex-row flex-col">
                      <FeatureBox>
                        <BoxText>Sponsor 100 meals</BoxText>
                        <BoxTextAmount>₹ {100 * 50}</BoxTextAmount>
                        <BoxButton as="a" href={`https://giveffl.iskconpunebcec.com/give.php?amt=${100 * 50}&`} css={buttonRoundedCss} className="mt-2">
                          Sponsor
                        </BoxButton>
                      </FeatureBox>
                      <FeatureBox>
                        <BoxText>Sponsor 200 meals</BoxText>
                        <BoxTextAmount>₹ {200 * 50}</BoxTextAmount>
                        <BoxButton as="a" href={`https://giveffl.iskconpunebcec.com/give.php?amt=${200 * 50}&`} css={buttonRoundedCss} className="mt-2">
                          Sponsor
                        </BoxButton>
                      </FeatureBox>
                    </div>
                  </div>
                  <FeatureList>
                      <div class="grid grid-cols-4 grid-rows-1">
                      { [300,400,450,500,750,1000].map((feature, index) => (
                          <>
                          <div class="col-span-2 md:col-span-1 py-2 items-center flex pr-3">
                            <FeatureBoxText>
                              {`Sponsor `}
                              <span class="font-bold">{feature}</span>
                              {` meals`}
                            </FeatureBoxText>
                          </div>
                          <div class="col-span-1 py-2 items-center flex">
                            <BoxTextAmount>{` ₹ ${feature * 50}`}</BoxTextAmount>
                          </div>
                          <div class="col-span-1 items-center flex justify-end">
                            <TableButton as="a" href={`https://giveffl.iskconpunebcec.com/give.php?amt=${feature * 50}&`} css={buttonRoundedCss}>
                              Sponsor
                            </TableButton>
                          </div>
                        </>
                      ))}
                    </div>
                  </FeatureList>
                  <PrimaryButton as="a" href={`https://giveffl.iskconpunebcec.com/give.php`} css={buttonRoundedCss}>
                    Donate Any Other Amount
                  </PrimaryButton>
        </>,
        imageSrc: ffl,
        imageBorder: true,
        imageDecoratorBlob: true,
        showButton: false
      }
    },
    'Sponsor in Kind': {
      left: {
        text:true,
        subheading: <Subheading>Sponsor in Kind</Subheading>,
        heading: <></>,
        description:  <>
          <Para>We accept donations in kind in any of the below ways</Para>
          <FeatureList>
            {[
              <> <span tw="text-primary-500">Grains</span></>,
              <> <span tw="text-primary-500">Ingredients</span></>,
              <> <span tw="text-primary-500">Vegetables</span></>,
              <> <span tw="text-primary-500">Transportation</span></>,
              <> <span tw="text-primary-500">Utility equipments, vessels etc.</span></>
            ].map((feature, index) => (
              <Feature key={index}>
                <FeatureIcon />
                <FeatureText>{feature}</FeatureText>
              </Feature>
            ))}
          </FeatureList>
          <PrimaryButton className="md:hidden" as="a" href='https://wa.me/919309471825' css={buttonRoundedCss}>
            Connect via WhatsApp
          </PrimaryButton>
          <PrimaryButton className="md:hidden ml-3" as="a" href='tel:919309471825' css={buttonRoundedCss}>
            Connect via Call
          </PrimaryButton>
        </>,
        imageSrc: ffl,
        imageBorder: true,
        imageDecoratorBlob: true,
        showButton: false
      },
    }
  }

  const youtubeCards = [
    { videoId: 'IYH73ytnsiM' },
    { videoId: 'Hn0uhjla3qE' },
    { videoId: 'Q8s-asgkJXQ' },
    { videoId: '6EKIsj6dNxk' },
    { videoId: 'QIebhrZTwpI' },
    { videoId: 'TgFNpZbfUrY' },
    { videoId: 'xA7KnoR1s3c' },
    { videoId: 'nVIFNEsb_XU' },
    { videoId: 'Nx_3PzA4i4Y' }
  ]

  return (
    <div className="font-display min-h-screen text-secondary-500 p-8 overflow-hidden">
      <FoodForLifeHero/>
      <FFLMetaPixelCode/>
      <TabWithoutImage heading="Donate Food For Life" tabs={fflDonationTabs}/>
      <RequestMessage>
        {`Gentle Request! While doing Paytm or Bank (NEFT/ RTGS) please send us
        screen shot on our Whatsapp No `}
        <Link href='https://wa.me/919309471825'>+919309471825.</Link>
        {` You may also call on this
        no for other queries`}
      </RequestMessage>
      <YoutubeVideo cards={youtubeCards}/>
      <About description = {aboutffl} heading="About the program" actions={false}/>
      <Testimonial testimonials = {testimonials} heading = "Testimonials" />
      <TieUps heading= "Our Tie Ups"  tieUps = { tieUps }/>
      <Footer />
    </div>
  )
};
