import React,{ useState } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import bannerImage from "images/hero-BG.png"
import BD1 from "images/BD-1.png"
import BD2 from "images/BD-2.jpeg"
import { ReactComponent as FacebookIcon } from "../../images/facebook-icon.svg";
import { ReactComponent as MapsIcon } from "../../images/map-icon.svg";
import { ReactComponent as TwitterIcon } from "../../images/twitter-icon.svg";
import { ReactComponent as YoutubeIcon } from "../../images/youtube-icon.svg";
import { ReactComponent as QuotesLeftIconBase } from "images/quotes-l.svg"
import ffl from "../../images/ffl.jpg";

import Header, { NavLink, NavLinks, PrimaryLink as PrimaryLinkBase, LogoLink, NavToggle, DesktopNavLinks } from "../headers/light.js";

const Content = styled.div(props => [
  tw`px-0 md:px-4 flex flex-1 flex-col justify-end items-center space-y-1`,
  !props.showValue ? tw`pb-4 lg:pb-10` : tw`lg:pb-4`
]);

const Container = styled.div(props => [
  tw`relative -mx-8 -mt-8 bg-contain sm:bg-contain md:bg-cover bg-ffl-small sm:bg-ffl-normal bg-no-repeat`,
  tw`min-h-ffl sm:min-h-96 md:min-h-128 xl:min-h-ffl`,
]);

const StyledHeader = styled(Header)`
  ${tw`max-w-none w-full `}
  ${tw`xl:pt-5 md:pt-3 pt-2`}
  ${DesktopNavLinks} ${NavLink}, ${LogoLink} {
    ${tw`text-gray-100 hover:border-gray-300 hover:text-gray-300 md:w-1/4`}
  }
  ${NavToggle}.closed {
    ${tw`text-gray-100 hover:text-primary-500`}
  }
  ${tw`fixed top-0 left-0 bg-ffl-small sm:bg-ffl-normal px-6`}
  `;
const PrimaryLink = tw(PrimaryLinkBase)`rounded-full mx-1`

const FloatingButton = tw(PrimaryLinkBase)`fixed z-50 bottom-0 right-0 mr-2 mb-2 md:mr-4 md:mb-4 bg-primary-600 w-20 h-10 md:w-20 md:h-20 text-xs md:text-base rounded-full shadow-lg flex justify-center items-center hover:bg-primary-700 hover:shadow-2xl hover:animate-bounce duration-300`

const OpacityOverlay = tw.div`z-10 absolute inset-0 bg-gradient-to-b from-black via-primary-900 to-gray-900 opacity-50`;

const HeroContainer = tw.div`z-20 relative px-6 sm:px-8 mx-auto h-full flex flex-col`;

const Heading = styled.h1`
  ${tw`text-2xl text-center sm:text-3xl lg:text-5xl xl:text-5xl font-black text-gray-100 leading-snug -mt-24 sm:mt-0`}
  span {
    ${tw`inline-block mt-2`}
  }
`;

const PrimaryAction = tw.a`rounded-full px-8 py-3 mt-10 text-sm sm:text-base sm:mt-16 sm:px-8 sm:py-4 bg-gray-100 font-bold shadow transition duration-300 bg-primary-500 text-gray-100 hocus:bg-primary-700 hocus:text-gray-200 focus:outline-none focus:shadow-outline`;

const SocialLinksContainer = tw.div`hidden md:inline-block md:w-full text-right`;
const SocialLink = styled.a`
  ${tw`cursor-pointer inline-block p-2 rounded-full bg-ffl-700 text-gray-900 hover:bg-ffl-900 transition duration-300 mr-4`}
  svg {
    ${tw`w-4 h-4`}
  }
`;

const Message = tw.div`max-w-sm md:max-w-3xl lg:max-w-4xl xl:max-w-5xl rounded-xl relative sm:absolute bottom-0 inset-x-0 z-20 px-6 py-4 sm:px-8 sm:pt-4 sm:pb-6 bg-primary-900 text-gray-400 font-medium transform text-sm leading-relaxed md:mx-auto md:mb-6 bg-opacity-75`
const QuotesLeftIcon = tw(QuotesLeftIconBase)`w-16 h-16 md:w-12 md:h-12 absolute top-0 left-0 text-gray-100 md:text-red-500 transform translate-x-1 md:-translate-x-1/2 md:-translate-y-5 opacity-10 md:opacity-100`
const MessageText = tw.blockquote`mb-0 md:mb-4`
const SubText1 = tw.p`mb-4 font-bold text-xl`
const SubText2 = tw.p`mt-1 text-sm text-gray-500`

const testimonial = {
  message: "Deepotsava or Deepotsavam is also known as the festival of lights, is celebrated in the month of Kartika (October – November) every year. It is a month-long festival commemorating Damodara-lila, the sweet pastime of baby Krishna stealing butter and consequently being tied to a mortar by His dear mother Yashoda. In Sanskrit, “dama” means rope and “udara” means belly. Damodara refers to Krishna who was bound with a rope by His mother Yashoda",
  subText1: "Kartik Maas",
  subText2: ""
}


export default ({
  heading = "Food For Life",
  description = "Every penny donated would help feed the needy and live",
  imageSrc = ffl,
  imageDecoratorBlob = true,
  primaryButtonUrl = "https://google.com",
  primaryButtonText = "Sponsor Any Amount",
  buttonRounded = true,
  features = [25,50,100,250,300,350,400,450,500,750,1000],
  testimonial = {
    quote: "In line with Srila Prabhupada, founder of ISKCON, decree and being inspired by his vision, we have launched Food for Life Program. Food for Life Program aims to serve food to mankind, with the same love and devotion that a mother provides for her own.",
    customerName: "Charlotte Hale",
    customerCompany: "Delos Inc."
  }
}) => {
  const [donateCount, setDonateCount] = useState();
  const buttonRoundedCss = buttonRounded && tw`rounded-full`;
  const navLinks = [
    <NavLinks key={1}>
      <NavLink href="/ffl">
        HOME
      </NavLink>
      <NavLink href="#sec+1">
        PROGRAMS
      </NavLink>
      <NavLink href="#sec+2">
        ABOUT US
      </NavLink>
      <NavLink href="/">
        ISKCON BCEC
      </NavLink>
    </NavLinks>,
    <NavLinks key={2} tw="md:w-1/5">
      {/* <PrimaryLink href="/#">
        Take Pledge
      </PrimaryLink> */}
      <SocialLinksContainer>
        <SocialLink href="https://www.facebook.com/iskconpunebcec/" target="_blank">
          <FacebookIcon />
        </SocialLink>
        <SocialLink href="https://www.youtube.com/channel/UCupgMMx2C9G6F6Lvv4EXSbg" target="_blank">
          <YoutubeIcon />
        </SocialLink>
        <SocialLink href="https://goo.gl/maps/fE8QXC7D6NR7ZNe37" target="_blank">
          <MapsIcon />
        </SocialLink>
      </SocialLinksContainer>
    </NavLinks>
  ];
  return (
    <Container>
      {/* <OpacityOverlay /> */}
      <HeroContainer>
        <StyledHeader links={navLinks} headerType="ffl"/>
        <FloatingButton target="_blank" href="https://giveffl.iskconpunebcec.com/give.php">
          Donate
        </FloatingButton>
        <Content>
        </Content>
      </HeroContainer>
    </Container>
  );
};
