import React, { useEffect, useState } from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/BackgroundAsImageWithCenteredContent.js";
import Footer from "components/footers/MainFooter";
import Contest from "components/popup/Contest";

export default (props) => {
  return (
    <AnimationRevealPage>
      <Hero showValue={true} />
        <Contest showAsPage={true}/>
      <Footer/>
    </AnimationRevealPage>
  )
};
