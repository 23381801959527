import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { SectionHeading as HeadingBase } from "components/misc/Headings";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons";
import SortByMarks from "documents/Result_Sort_By_Marks.pdf"
import SortBySchool from "documents/Result_Sort_By_School.pdf"

const PrimaryButton = styled(PrimaryButtonBase)(props => [
	tw`mt-auto sm:text-lg py-1 m-1 rounded-full`,
	props.selected && tw`bg-primary-700 text-gray-200 shadow-outline outline-none`
]);
const Heading = tw(HeadingBase)`text-center text-primary-900 leading-snug text-2xl md:text-3xl`;
const LocationHeading = tw(HeadingBase)`text-left text-primary-900 leading-snug text-xl md:text-2xl`;
const Para = tw.p`text-base leading-relaxed`;
const SocialLink = styled.a`
  ${tw`cursor-pointer inline-block p-1 rounded-full bg-gray-700 text-gray-100 hover:bg-gray-900 transition duration-300 mr-1`}
  svg {
    ${tw`w-3 h-3`}
  }
`;

export default ({showAsPage = false}) => {
	const modalProps = showAsPage ? {
		'role': 'dialog',
		'aria-hidden':'true'
	} : {}

	return (
		<div id='contest' tabindex="-1" class={`overflow-y-auto overflow-x-hidden w-full md:inset-0 md:h-full justify-center items-center ${!showAsPage && 'fixed top-0 right-0 left-0 z-50 h-modal hidden'}`} {...modalProps}>
			<div class={`relative w-full max-w-7xl md:max-w-full h-full md:h-auto ${!showAsPage && 'p-4'}`}>
				<div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
					<div class="flex justify-between items-center p-4 rounded-t border-b dark:border-gray-600">
						<Heading>
							Value Education Contest (Result)
						</Heading>
						{!showAsPage && <button type="button" class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-toggle="dysLocations">
							<svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
							<span class="sr-only">Close modal</span>
						</button>}
					</div>
					<div class="px-6 space-y-6 overflow-y-auto" style={{ height: '30vh' }}>
						<div class="flex flex-col">
							<h2>Dear Students,</h2>
							<p>Below are the results for Value Education Contest</p>
							<br/>
							<ol>
								<li><strong>First rank:</strong>Diya Shah</li>
								<li><strong>Second rank:</strong>Nainika Kaul</li>
								<li><strong>Third rank:</strong>Shreya Mankar</li>
							</ol>
							<br/>
							<p><b>Consolation prizes:</b></p>
							<ol>
								<li>Vihaan patil</li>
								<li>Shreepal Gavali</li>
								<li>Aarav Bhimani</li>
							</ol>
						</div>
					</div>
				</div>
				<div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
					<div class="flex justify-between items-center p-4 rounded-t border-b dark:border-gray-600">
						<Heading>
							Krishna Book Contest (Exam Result)
						</Heading>
						{!showAsPage && <button type="button" class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-toggle="dysLocations">
							<svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
							<span class="sr-only">Close modal</span>
						</button>}
					</div>
					<div class="px-6 space-y-6 overflow-y-auto" style={{ height: '30vh' }}>
						{/* <div class="flex flex-col">
							<h2>Dear Students,</h2>
							<p>Please take note of the following instructions for your upcoming exam:</p>
							<br/>
							<ol>
								<li><strong>Exam Schedule:</strong> The exam is scheduled from <strong>1st February 2024</strong> to <strong>7th February 2024</strong>.</li>
								<li><strong>Internet Connection:</strong> Ensure you have a moderate internet connection as this is an online exam.</li>
								<li><strong>Multiple Submissions:</strong> Multiple submissions are allowed; however, only the last submission will be considered.</li>
								<li><strong>Booklet Code:</strong> Enter your unique booklet code accurately in the exam form. Do not share this code with anybody else.</li>
								<li><strong>Time Management:</strong> Keep an eye on the timer displayed on the right side of the screen and submit your exam before the time expires.</li>
								<li><strong>Device Compatibility:</strong> You can use mobile devices to take the exam.</li>
								<li><strong>Mandatory Fields:</strong> Fill in all mandatory fields accurately.</li>
								<li><strong>Take Your Time:</strong> Take your time while answering the questions. Avoid rushing and children should read carefully the chapter number and question number.</li>
								<li><strong>Availability:</strong> The exam will remain open from 1st - 7th February.</li>
								<li><strong>Result Announcement:</strong> Results will be announced as per the schedule (Result Date) on our website/Contest Results.</li>
								<li><strong>Contact Information:</strong>
								<ul style={{paddingLeft: '20px'}}>
									<li>Pranskahi Mataji - <a style={{color: 'blue'}} href="tel:9730802221">9730802221</a></li>
									<li>Namrata Mataji - <a style={{color: 'blue'}} href="tel:9730802221">9860268842</a></li>
								</ul>
								</li>
								<li><strong>Availability:</strong> The exam will remain open for one day initially and later on a need basis.</li>
								<li><strong>Queries:</strong> For any queries related to the exam or submission, please contact HG Lokeshwar Pr at 9182324094.</li>
							</ol>
							<br/>
							<p>Here is the link to access the exam: <a style={{color: 'blue'}} href="https://forms.office.com/r/a4xgYJX6VK"><strong>Exam Link</strong></a></p>
							<br/>
							<p>Please review these instructions carefully and reach out if you have any concerns or questions.</p>
						</div> */}
					
						<div class="flex flex-col"></div>
						<h2>Dear Students,</h2>
						<p>Thank you so much for your enthusiatic participation.
						<br/>Please click on any of the links below to see the results.</p>
						<p>
							<a style={{color: 'blue', paddingRight: '30px'}} href={SortByMarks}><strong>Results by Marks</strong></a>
							<a style={{color: 'blue'}} href={SortBySchool}><strong>Results by School</strong></a>
						</p>
					</div>
				</div>
			</div>
		</div>
	)
}
