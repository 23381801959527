import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";


const Container = tw.div`relative w-full`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between w-full md:max-w-screen-2xl mx-auto items-center items-stretch`;
const Column = tw.div`w-full`;
const TextColumn = styled(Column)(props => [
  !props.text ? tw`hidden` : '',
  props.full ? tw`mt-5 md:mt-0` : tw`md:w-1/2 mt-5 md:mt-0`,
  tw`md:mx-6 lg:mx-8 md:order-first`
]);

const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = styled(SectionHeading)(props => [
  props.subheading ? tw`mt-4` : 'mt-0',
  tw`font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`]);
const Description = styled.p(props => [
  props.heading && props.subheading ? tw`mt-4` : ``,
  tw`text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`
]);

const PrimaryButton = styled(PrimaryButtonBase)(props => [
  tw`mt-8 md:mt-8 text-sm inline-block mx-auto md:mx-0`,
  props.buttonRounded && tw`rounded-full`
]);


export default ({
  right = {
    subheading : null,
    heading : null,
    descriptionLeft : "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    descriptionRight : "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    primaryButtonText : "Learn More",
    primaryButtonUrl : "#",
    primaryButtonModalToggle : 'aboutISKCON',
    buttonRounded : true,
    showButton : true,
    text : false,
  },
  left = {
    subheading : null,
    heading : null,
    descriptionLeft : "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    descriptionRight : "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    primaryButtonText : "Learn More",
    primaryButtonUrl : "#",
    primaryButtonModalToggle : 'aboutISKCON',
    buttonRounded : true,
    text : false,
    showButton : true,
  }
}) => {
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.

  return (
    <Container>
      <TwoColumn>
        <TextColumn text={left?.text} full={!right?.text}>
          <TextContent>
            {left.subheading &&<Subheading>{left.subheading}</Subheading>}
            {left.heading && <Heading subheading={left.subheading}>{left.heading}</Heading>}
            <Description subheading={left.subheading} heading={left.heading}>{left.description}</Description>
            {left.showButton && <PrimaryButton buttonRounded={left.buttonRounded} as="a" href={left.primaryButtonUrl} data-modal-toggle={left.primaryButtonModalToggle ? left.primaryButtonModalToggle : ''}>
              {left.primaryButtonText}
            </PrimaryButton>}
          </TextContent>
        </TextColumn>
        <TextColumn text={right?.text}>
          <TextContent>
            {right.subheading &&<Subheading>{right.subheading}</Subheading>}
            {right.heading && <Heading subheading={right.subheading}>{right.heading}</Heading>}
            <Description subheading={right.subheading} heading={right.heading}>{right.description}</Description>
            {right.showButton && <PrimaryButton buttonRounded={right.buttonRounded} as="a" href={right.primaryButtonUrl} data-modal-toggle={right.primaryButtonModalToggle ? right.primaryButtonModalToggle : ''}>
              {right.primaryButtonText}
            </PrimaryButton>}
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};
