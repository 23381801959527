import React, { useState } from "react";
import { motion } from "framer-motion";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
import { SectionHeading } from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import { ReactComponent as StarIcon } from "images/star-icon.svg";
import { ReactComponent as SvgDecoratorBlob1 } from "images/svg-decorator-blob-5.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "images/svg-decorator-blob-7.svg";
import { ReactComponent as CheckboxIcon } from "feather-icons/dist/icons/check-circle.svg";
import MainFeature from "components/features/TwoColWithButton.js";
import DYS from "images/DIY.jpeg";
import Bhagavatam from "images/bhagavatam.jpeg";
import ShriNarsimha from "images/shri-narsimha.jpg";
import KK from "images/KK.jpg";
import BG from "images/BG.webp";
import DYSLocations from "components/popup/DYSLocations"; 

const HeaderRow = tw.div`flex justify-between items-center flex-col xl:flex-row`;
const Header = tw(SectionHeading)``;
const TabsControl = tw.div`flex flex-wrap bg-gray-200 px-2 py-2 rounded leading-none mt-12 xl:mt-0 rounded-xl`;

const TabControl = styled.div`
  ${tw`cursor-pointer px-6 py-3 mt-2 sm:mt-0 sm:mr-2 last:mr-0 text-gray-600 font-medium rounded-sm transition duration-300 text-sm sm:text-base w-1/2 sm:w-auto text-center rounded-xl`}
  &:hover {
    ${tw`bg-gray-300 text-gray-700`}
  }
  ${props => props.active && tw`bg-primary-500! text-gray-100!`}
  }
`;

const TabContent = tw(motion.div)`mt-6 flex flex-wrap sm:-mr-10 md:-mr-6 lg:-mr-12`;
const CardContainer = tw.div`mt-10 w-full sm:w-1/2 md:w-1/3 lg:w-1/4 sm:pr-10 md:pr-6 lg:pr-12`;
const Card = tw(motion.a)`bg-gray-200 rounded-b block max-w-xs mx-auto sm:max-w-none sm:mx-0`;
const CardImageContainer = styled.div`
  ${props => css`background-image: url("${props.imageSrc}");`}
  ${tw`h-56 xl:h-64 bg-center bg-cover relative rounded-t`}
`;
const CardRatingContainer = tw.div`leading-none absolute inline-flex bg-gray-100 bottom-0 left-0 ml-4 mb-4 rounded-full px-5 py-2 items-end`;
const CardRating = styled.div`
  ${tw`mr-1 text-sm font-bold flex items-end`}
  svg {
    ${tw`w-4 h-4 fill-current text-primary-400 mr-1`}
  }
`;

const CardHoverOverlay = styled(motion.div)`
  background-color: rgba(255, 255, 255, 0.5);
  ${tw`absolute inset-0 flex justify-center items-center`}
`;
const CardButton = tw(PrimaryButtonBase)`text-sm`;

const CardReview = tw.div`font-medium text-xs text-gray-600`;

const CardText = tw.div`p-4 text-gray-900`;
const CardTitle = tw.h5`font-semibold group-hover:text-primary-500`;
const CardContent = tw.p`mt-1 text-sm font-medium text-gray-600`;
const CardPrice = tw.p`mt-4 font-bold`;

const Subheading = tw.span`uppercase tracking-widest font-bold text-primary-500`;


const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none -z-20 absolute right-0 top-0 h-64 w-64 opacity-15 transform translate-x-2/3 -translate-y-12 text-pink-400`}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none -z-20 absolute left-0 bottom-0 h-80 w-80 opacity-15 transform -translate-x-2/3 text-primary-500`}
`;

const FeatureList = tw.ul`mt-5 leading-loose`;
const Feature = tw.li`flex items-start md:items-center`;
const FeatureIcon = tw(CheckboxIcon)`w-3 md:w-5 h-3 md:h-5 mt-2 md:mt-0 text-primary-500 flex-shrink-0`;
const FeatureText = tw.p`ml-2 font-medium text-left`;
const Para = tw.p`leading-relaxed font-medium text-gray-700`;

/*
      {
        imageSrc:
          "https://images.unsplash.com/photo-1512621776951-a57141f2eefd?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=80",
        title: "For All (English)",
        content: "By HG Kanai Thakur Prabhuji",
        price: "Friday | 6.30 PM to 7.45 PM",
        rating: "5.0",
        reviews: "87",
        url: "#"
      }
*/

export default ({
  heading = "Our Programs",
  tabs = {
    // 'Upcoming Festival(s)': {
    //   subheading: <Subheading>Appearance Day of Lord Narsimhadev</Subheading>,
    //   heading: (<>
    //     Shri Narsimha Chaturdashi Mahamahotsav (4th May 2023)
    //   </>),
    //   description: <>
    //     <Para>Schedule for the Festival at ISKCON BCEC</Para>
    //     <FeatureList>
    //       {[
    //         <> <span tw="text-primary-500">05:00 PM</span> Kirtans</>,
    //         <> <span tw="text-primary-500">06:00 PM</span> Maha Abhishek</>,
    //         <> <span tw="text-primary-500">06:45 PM</span> Katha By HG Kanai Thakur Pr</>,
    //         <> <span tw="text-primary-500">07:30 PM</span> Special Drama</>,
    //         <> <span tw="text-primary-500">08:00 PM</span> Maha Arati </>,
    //         <> <span tw="text-primary-500">08:00 PM</span> Prasadam for all </>
    //       ].map((feature, index) => (
    //         <Feature key={index}>
    //           <FeatureText>{feature}</FeatureText>
    //         </Feature>
    //       ))}
    //     </FeatureList>
    //   </>,
    //   primaryButtonText: "Donate",
    //   primaryButtonUrl: "https://give.iskconpunebcec.com/",
    //   imageSrc: ShriNarsimha,
    //   imageBorder: true,
    //   imageDecoratorBlob: true,
    //   primaryButtonModalToggle: '#'
    // },
    'DYS': {
      subheading: <Subheading>Discover Your Self</Subheading>,
      heading: (<>
        <span tw="text-primary-500">Finding answers</span> to life’s eternal questions from the Gita.
      </>),
      description: <>
        <Para>These interactive sessions will be based on Bhagavad Gita shlokas and we will discuss basics of spirituality. It will specifically focus on 5S including:</Para>
        <FeatureList>
          {[
            <> <span tw="text-primary-500">Soul :</span> Answer the question Who am I?</>,
            <> <span tw="text-primary-500">Supreme :</span> Is there an intelligent creator of this world?</>,
            <> <span tw="text-primary-500">Scriptures :</span> What is the significance of scriptures in everyday life?</>,
            <> <span tw="text-primary-500">Solutions :</span> Are there any practical vedic solutions to our problems?</>,
            <> <span tw="text-primary-500">Sound Vibration :</span> What is mantra and it's significance?</>
          ].map((feature, index) => (
            <Feature key={index}>
              <FeatureIcon />
              <FeatureText>{feature}</FeatureText>
            </Feature>
          ))}
        </FeatureList>
      </>,
      primaryButtonText: "Nearest DYS Program",
      primaryButtonModalToggle: "dysLocations",
      imageSrc: DYS,
      imageBorder: true,
      imageDecoratorBlob: true
    },
    'Bhagavad-Gītā': {
      subheading: <Subheading>Why should I learn Bhagavad Gītā</Subheading>,
      heading: null,
      description: <>
        <Para>The Art of Mind Control and Stress Management. Ability to transcend all confusion and doubts of life.</Para>
        <Para>Five primary subject matters which are covered in this course are:</Para>
        <FeatureList>
          {[
            <> <span tw="text-primary-500">Isvara :</span> Who is God?</>,
            <> <span tw="text-primary-500">Jiva :</span> Who am I?</>,
            <> <span tw="text-primary-500">Prakriti :</span> Nature</>,
            <> <span tw="text-primary-500">Kaala :</span> Eternal time</>,
            <> <span tw="text-primary-500">Karma :</span> Art of smart work</>
          ].map((feature, index) => (
            <Feature key={index}>
              <FeatureIcon />
              <FeatureText>{feature}</FeatureText>
            </Feature>
          ))}
        </FeatureList>
      </>,
      primaryButtonText: "Register",
      imageSrc: BG,
      imageBorder: true,
      imageDecoratorBlob: true,
      showButton: false
    },
    'Śrīmad-Bhāgavatam': {
      subheading: <Subheading>Stories of the Lord and his incarnations</Subheading>,
      heading: null,
      description: <>
        <Para>Srimad Bhagvatam sessions are dedicated to understanding the various pastimes of Lord Sri Krishna. This course opens up the treasure chest of vedic knowledge.</Para>
      </>,
      primaryButtonText: "Register",
      imageSrc: Bhagavatam,
      imageBorder: true,
      imageDecoratorBlob: true,
      showButton: false
    },
    "Krishna's Kingdom": {
      subheading: <Subheading>Fun filled vedic values</Subheading>,
      heading: null,
      description: <>
        <Para>Weekend program specially designed for little ones to engage and teach them vedic values in a fun way with lot of stories, mantra, music, dance and cultural activities.</Para>
      </>,
      primaryButtonText: "Register",
      imageSrc: KK,
      imageBorder: true,
      imageDecoratorBlob: true,
      showButton: false
    }
  }
}) => {
  /*
   * To customize the tabs, pass in data using the `tabs` prop. It should be an object which contains the name of the tab
   * as the key and value of the key will be its content (as an array of objects).
   * To see what attributes are configurable of each object inside this array see the example above for "Starters".
   */
  const tabsKeys = Object.keys(tabs);
  const [activeTab, setActiveTab] = useState(tabsKeys[0]);

  return (
    <Container>
      <DYSLocations/>
      <ContentWithPaddingXl>
        <HeaderRow>
          <Header>{heading}</Header>
          <TabsControl>
            {Object.keys(tabs).map((tabName, index) => (
              <TabControl key={index} active={activeTab === tabName} onClick={() => setActiveTab(tabName)}>
                {tabName}
              </TabControl>
            ))}
          </TabsControl>
        </HeaderRow>

        {tabsKeys.map((tabKey, index) => (
          <TabContent
            key={index}
            variants={{
              current: {
                opacity: 1,
                scale: 1,
                display: "flex",
              },
              hidden: {
                opacity: 0,
                scale: 0.8,
                display: "none",
              }
            }}
            transition={{ duration: 0.4 }}
            initial={activeTab === tabKey ? "current" : "hidden"}
            animate={activeTab === tabKey ? "current" : "hidden"}
          >
            {!tabs[tabKey].length ?
              <MainFeature {...tabs[tabKey]} />
              : tabs[tabKey].map((card, index) => (
                <CardContainer key={index}>
                  <Card className="group" href={card.url} initial="rest" whileHover="hover" animate="rest">
                    <CardImageContainer imageSrc={card.imageSrc}>
                      {/* <CardRatingContainer>
                      <CardRating>
                        <StarIcon />
                        {card.rating}
                      </CardRating>
                      <CardReview>({card.reviews})</CardReview>
                    </CardRatingContainer> */}
                      <CardHoverOverlay
                        variants={{
                          hover: {
                            opacity: 1,
                            height: "auto"
                          },
                          rest: {
                            opacity: 0,
                            height: 0
                          }
                        }}
                        transition={{ duration: 0.3 }}
                      >
                        <CardButton>Register</CardButton>
                      </CardHoverOverlay>
                    </CardImageContainer>
                    <CardText>
                      <CardTitle>{card.title}</CardTitle>
                      <CardContent>{card.content}</CardContent>
                      <CardPrice>{card.price}</CardPrice>
                    </CardText>
                  </Card>
                </CardContainer>
              ))}
          </TabContent>
        ))}
      </ContentWithPaddingXl>
      <DecoratorBlob1 />
      <DecoratorBlob2 />
    </Container>
  );
};
