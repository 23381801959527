import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line

import LogoImage from "../../images/logo-full.png";
import { ReactComponent as FacebookIcon } from "../../images/facebook-icon.svg";
import { ReactComponent as TwitterIcon } from "../../images/twitter-icon.svg";
import { ReactComponent as YoutubeIcon } from "../../images/youtube-icon.svg";
import { ReactComponent as MapsIcon } from "../../images/map-icon.svg";

import Privacy from "documents/Privacy Policy BCEC.pdf";
import TnC from "documents/Terms and Conditions BCEC.pdf";

const Container = tw.div`relative bg-gray-200 -mx-8 -mb-8 px-8`;
const FiveColumns = tw.div`max-w-screen-xl mx-auto pt-16 lg:pt-20 flex flex-wrap justify-between`;

const Column = tw.div`md:w-1/5`;
const WideColumn = tw(Column)`text-center md:text-left w-full md:w-2/5 mb-10 md:mb-0`;

const ColumnHeading = tw.h5`font-bold`;

const LinkList = tw.ul`mt-4 text-sm font-medium justify-end`;
const LinkListItem = tw.li`mt-1 flex flex-row justify-end`;
const Link = tw.div`border-transparent`;
const LinkEmail = tw.a`border-b-2 border-transparent hocus:font-semibold pb-1 transition duration-300`;
const LinkBold = tw.div`font-semibold mr-3`;

const LogoContainer = tw.div`flex items-center justify-center md:justify-start`;
const LogoImg = tw.img`w-8`;
const LogoText = tw.h5`text-xl font-black text-primary-500`;

const CompanyDescription = tw.p`mt-4 max-w-xs font-medium text-sm mx-auto md:mx-0 md:mr-4 `;

const SocialLinksContainer = tw.div`mt-4 `;
const SocialLink = styled.a`
  ${tw`cursor-pointer inline-block p-2 rounded-full bg-gray-700 text-gray-100 hover:bg-gray-900 transition duration-300 mr-4`}
  svg {
    ${tw`w-4 h-4`}
  }
`;

const CopyrightText = tw.p`text-center pb-5 pt-5 lg:pt-16 font-medium tracking-wide text-sm text-gray-600`;

export default () => {
  return (
    <Container>
      <FiveColumns>
        <WideColumn>
          <LogoContainer>
            {/* <LogoImg src={LogoImage} /> */}
            <LogoText>ISKCON BCEC, Pune</LogoText>
          </LogoContainer>
          <CompanyDescription>
            Behind Chondhe Patil Sports Complex, Vishal Nagar, Pimple Nilakh, Pimpri-Chinchwad, Maharashtra 411027
          </CompanyDescription>
          <SocialLinksContainer>
            <SocialLink href="https://www.facebook.com/iskconpunebcec/" target="_blank">
              <FacebookIcon />
            </SocialLink>
            <SocialLink href="https://www.youtube.com/channel/UCupgMMx2C9G6F6Lvv4EXSbg" target="_blank">
              <YoutubeIcon />
            </SocialLink>
            <SocialLink href="https://goo.gl/maps/fE8QXC7D6NR7ZNe37" target="_blank">
              <MapsIcon />
            </SocialLink>
          </SocialLinksContainer>
          <LinkList tw="justify-start">
            <LinkListItem tw="justify-start">
              +91 93094 71825
            </LinkListItem>
            <LinkListItem tw="justify-start">
              <LinkEmail href="mailto:iskconpunebcec@gmail.com">iskconpunebcec@gmail.com</LinkEmail>
            </LinkListItem>
          </LinkList>
        </WideColumn>
        <WideColumn tw="text-right">
          <ColumnHeading>Temple Schedule</ColumnHeading>
          <LinkList>
            <LinkListItem>
              <LinkBold>Sri Shikshashtakam</LinkBold>
              <Link>04:30 am to 04:45 am</Link>
            </LinkListItem>
            <LinkListItem>
              <LinkBold>Chanting Session</LinkBold>
              <Link>04:45 am to 06:45 am</Link>
            </LinkListItem>
            <LinkListItem>
              <LinkBold>Srimad bhagwatam class</LinkBold>
              <Link>06:45 am to 07:30 am</Link>
            </LinkListItem>
            <LinkListItem>
              <LinkBold>Aarti & Gurupuja</LinkBold>
              <Link>07:30 am to 08:20 am</Link>
            </LinkListItem>
            <LinkListItem>
            </LinkListItem>
            <LinkListItem>
              <LinkBold>Temple Closed</LinkBold>
              <Link>01:00 pm to 04:00 pm</Link>
            </LinkListItem>
            <LinkListItem>
              <LinkBold>Gaur Aarti</LinkBold>
              <Link>06:45 pm to 07:15 pm</Link>
            </LinkListItem>
            <LinkListItem>
              <LinkBold>Shayan Aarti</LinkBold>
              <Link>08:00 pm to 08:15 pm</Link>
            </LinkListItem>
            <LinkListItem>
            </LinkListItem>
            <LinkListItem>
              <LinkBold>Temple Closed</LinkBold>
              <Link>08:15 pm to 04:15 am</Link>
            </LinkListItem>
          </LinkList>
        </WideColumn>
      </FiveColumns>
      <CopyrightText>
        &copy; Copyright ISKCON BCEC - 2023, All Rights Reserved.
        <br/><a href={TnC} target="_blank">Terms and Conditions</a>&nbsp;&nbsp;
        <a href={Privacy} target="_blank">Privacy Policy</a>
      </CopyrightText>
    </Container>
  );
};
