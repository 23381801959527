import React, { useState } from "react";
import Slider from "react-slick";
import tw from "twin.macro";
import styled from "styled-components";
import { SectionHeading as HeadingBase } from "components/misc/Headings";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons";

const PrimaryButton = tw(PrimaryButtonBase)`mt-auto sm:text-lg py-3`;
const Heading = tw(HeadingBase)`text-center text-primary-900 leading-snug text-2xl md:text-3xl`;
const Para = tw.p`text-base leading-relaxed`;

export default () => {
  return (
    <div id="philosophy" tabindex="-1" class="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-modal md:h-full justify-center items-center hidden" role="dialog" aria-hidden="true">
      <div class="relative p-4 w-full max-w-7xl md:max-w-full h-full md:h-auto">
        <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
          <div class="flex justify-between items-center p-4 rounded-t border-b dark:border-gray-600">
            <Heading>
              Philosophy - Bhakti Yoga
            </Heading>
            <button type="button" class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-toggle="philosophy">
              <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
              <span class="sr-only">Close modal</span>
            </button>
          </div>
          <div class="p-6 space-y-6">
            <Para>
              Yoga is more than just a physical exercise. The word “yoga” comes from the Sanskrit root Yuj which means to link up with, or combine. Bhakti is derived from the Sanskrit word bhaj, which means – loving service. Bhakti-yoga means to connect to the Supreme by means of loving devotional service.            </Para>
            <Para>
              The Bhagavad Gita, the core spiritual text for ISKCON, describes variety of yoga practices. Among them are karma-yoga (the practice of conscious action), jnana-yoga (philosophical study and contemplation), and hatha-yoga (the practice of yoga-asanas and breathing exercises).            </Para>
            <Para>
            Today, some yoga practitioners consider the physical benefits of yoga to be the end in themselves. But according to the traditional yoga systems, physical exercises are just one step on path of God realization. The Gita ultimately prescribes bhakti-yoga (the path of dedication and love) as the culmination of other yoga practices. Bhakti-yoga focuses on developing our dedication, service and love for the Divinity, Lord Krishna.            </Para>
            <Para>
            The path of bhakti-yoga is developed through a variety of activities. These include mantra meditation, or the chanting of the names of God. The chanting is done either individually on beads (japa) or in community by chanting mantras accompanied by music (kirtan). The study of sacred texts such as the Bhagavad-gita and Srimad Bhagavatam, associating with like-minded spiritual aspirants, eating sanctified vegetarian food, and living in a way that upholds the principles of truthfulness, mercy, austerity, and cleanliness, are all core practices for a life of follower of bhakti.            </Para>
          </div>
        </div>
      </div>
    </div>
  )
}
