import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import tw from "twin.macro";
import styled from "styled-components";
import { SectionHeading as HeadingBase } from "components/misc/Headings";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons";
import locations from "content/DYSLocations.json"
import { ReactComponent as MapsIcon } from "../../images/map-icon.svg";
import { ReactComponent as WhatsAppIcon } from "../../images/whatsApp-icon.svg";


const PrimaryButton = styled(PrimaryButtonBase)(props => [
	tw`mt-auto sm:text-lg py-1 m-1 rounded-full`,
	props.selected && tw`bg-primary-700 text-gray-200 shadow-outline outline-none`
]);
const Heading = tw(HeadingBase)`text-center text-primary-900 leading-snug text-2xl md:text-3xl`;
const LocationHeading = tw(HeadingBase)`text-left text-primary-900 leading-snug text-xl md:text-2xl`;
const Para = tw.p`text-base leading-relaxed`;
const SocialLink = styled.a`
  ${tw`cursor-pointer inline-block p-1 rounded-full bg-gray-700 text-gray-100 hover:bg-gray-900 transition duration-300 mr-1`}
  svg {
    ${tw`w-3 h-3`}
  }
`;

const addressSection = (location) => (
	<>
		<LocationHeading>{location}</LocationHeading>
		<div className="flex flex-col sm:flex-row flex-wrap md:space-x-4 md:mb-6 mb-10">
			{Object.keys(locations[location]).map((locationName,i) => (
				<div className={`flex flex-col my-4 max-w-sm shrink-0 ${i===0 && 'ml-4'}`}>
					<div className="flex justify-between">
						<div className="text-xl font-bold mr-2 text-primary-500">{locationName}</div>
						<div>
							{locations[location][locationName].location &&
								<SocialLink href={locations[location][locationName].location} target="_blank">
									<MapsIcon />
								</SocialLink>
							}
							{locations[location][locationName].waLink &&
								<SocialLink href={locations[location][locationName].waLink} target="_blank">
									<WhatsAppIcon />
								</SocialLink>
							}
						</div>
					</div>
					<div className="inline-flex">
						<b class="mr-1">Address: </b>
						<span>{locations[location][locationName].address}</span>
					</div>
					<div className="inline-flex">
						<b class="mr-1">Landmark: </b>
						<span>{locations[location][locationName].landmark ? locations[location][locationName].landmark : ' - '}</span>
					</div>
					<div className="inline-flex">
						<b class="mr-1">Contact No: </b>
						<span>{locations[location][locationName].contact ? locations[location][locationName].contact : ' - '}</span>
					</div>
					<div className="inline-flex">
						<b class="mr-1">Start Date: </b>
						<span>{locations[location][locationName].startDate ? locations[location][locationName].startDate : ' - '}</span>
					</div>
					<div className="inline-flex">
						<b class="mr-1">Class Time: </b>
						<span>{locations[location][locationName].dateTime ? locations[location][locationName].dateTime : ' - '}</span>
					</div>
				</div>
			))}
		</div>
	</>
)

export default ({showAsPage = false}) => {
	const [locationNames] = useState(() => Object.keys(locations));
	const [selectedLocation, setSelectedLocation] = useState('All')

	const modalProps = showAsPage ? {
		'role': 'dialog',
		'aria-hidden':'true'
	} : {}

	return (
		<div id="dysLocations" tabindex="-1" class={`overflow-y-auto overflow-x-hidden w-full md:inset-0 md:h-full justify-center items-center ${!showAsPage && 'fixed top-0 right-0 left-0 z-50 h-modal hidden'}`} {...modalProps}>
			<div class={`relative w-full max-w-7xl md:max-w-full h-full md:h-auto ${!showAsPage && 'p-4'}`}>
				<div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
					<div class="flex justify-between items-center p-4 rounded-t border-b dark:border-gray-600">
						<Heading>
							DYS Program Locations
						</Heading>
						{!showAsPage && <button type="button" class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-toggle="dysLocations">
							<svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
							<span class="sr-only">Close modal</span>
						</button>}
					</div>
					<div class="px-6 space-y-6 overflow-y-auto" style={{ height: '80vh' }}>
						<div class="flex flex-col">
							<div class="inline-flex flex-wrap my-4">
								{
									locationNames && locationNames.length
										? ['All', ...locationNames].map((location) =>
											<PrimaryButton onClick={() => setSelectedLocation(location)} selected={location === selectedLocation}>
												{location}
											</PrimaryButton>
										)
										: 'No Active locations'}
							</div>
							<div>
								{
									selectedLocation === 'All'
										? locationNames.map((location) => addressSection(location))
										: addressSection(selectedLocation)
								}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
