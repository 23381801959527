import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import heroScreenshotImageSrc from "images/demo/MainLandingPageHero.png";
import { SectionHeading as HeadingBase } from "components/misc/Headings";
import { SectionDescription as DescriptionBase } from "components/misc/Typography";
import AnchorLink from "react-anchor-link-smooth-scroll";
import AboutISKCON from "components/popup/AboutISKCON";
import FounderAcharya from "components/popup/FounderAcharya";
import Philosophy from "components/popup/Philosophy";
import Purpose from "documents/Purpose.pdf";

const ActionButtonClass = `px-8 py-3 font-bold rounded bg-primary-500 text-gray-100 hocus:bg-primary-700 hocus:text-gray-200 focus:shadow-outline focus:outline-none transition duration-300 mt-12 inline-block tracking-wide text-center px-10 py-4 font-semibold tracking-normal`;
const SecondaryButtonClass = `mt-6 sm:mt-12 sm:ml-8 bg-gray-300 text-gray-800 hocus:bg-gray-400 hocus:text-gray-900`;

const Row = tw.div`flex`;
const Container = tw(Row)`flex-col lg:flex-row justify-between items-center pt-8 lg:pt-12 pb-16 max-w-screen-xl mx-auto flex-wrap`;

const Column = tw.div`flex-1`;
const Heading = styled(HeadingBase)(props => [
  props.headingLeftCss ? tw`lg:text-left` : '',
  tw`text-center leading-snug`
]);
const TextColumn = tw(Column)`mx-auto lg:mr-0 flex-shrink-0`;
const Description = tw(
  DescriptionBase
)`mt-4 text-center lg:text-left lg:text-base text-gray-700 mx-auto lg:mx-0 max-w-full`;
const Actions = tw.div`flex flex-col sm:flex-row justify-center`;
const ActionButton = tw(
  AnchorLink
)`${ActionButtonClass}`

const PrimaryButton = tw(ActionButton)``;
const SecondaryButton = tw(
  ActionButton
)`${SecondaryButtonClass}`;

const SecondaryButtonLink = tw.a`${ActionButtonClass} ${SecondaryButtonClass}`;

const ImageColumn = tw(Column)`mx-auto lg:mr-0 relative mt-16 lg:mt-0 lg:ml-8`;
const ImageContainer = tw.div``;
const Image = tw.img`max-w-full rounded-t sm:rounded`;

const defaultHeading = "About ISKCON BCEC";
const defaultDescription = <div>
   Situated in suburbs of Pimpri-Chinchwad City, ISKCON BCEC is a project of the International Society for Krishna Consciousness (ISKCON) and is located in BCEC at Pimple Nilakh.
          <br />
          It is currently operational through a 3000 sq ft rented abandoned Badminton hall of a Sports Complex, and was started with a motive to fulfil the need of locally situated congregation, who could have an avenue for regular & daily nourishment in their spiritual practices.
          <br />
          ISKCON BCEC is not an overnight establishment, but has taken shape due to the consistent endeavour by the leadership of ISKCON Pune. Senior monks from ISKCON Pune, have been travelling to this vicinity every week, for more than 12 years. And with a motto of fulfilling the seven purposes of ISKCON stated by ISKCON's Founder Acharya - His Divine Grace A. C. Bhaktivedanta Swami Prabhupada - with great care and patience, ISKCON BCEC has been cultivating a group of families. A bubbling and enthusiastic group of devotees is now growing, and the programs are being conducted for all age groups - children, teens, youths, and families together.
          <br />
          At the centre -
          <br />
          The resident monks ensure daily Morning Program, Weekly Sunday Programs are thronged by 300 Congregation Members. Residents hold around a dozen online and offline programs.
          <br />
          The centre is also looking for opportunities "To erect for the members and society at large, a holy place of transcendental pastimes, dedicated to the personality of Krishna"
          <br />
          - A Temple which is a University for the seekers, a Hospital for the hopeful, a family for the lonely, and "a home where the whole world can live."
</div>

export default (props) => {
  const {heading = defaultHeading, description = defaultDescription, actions=false, headingLeftCss } = props;
  
  return (
    <Container>
      <TextColumn>
        <Heading as="h1" headingLeftCss={headingLeftCss} >{heading}</Heading>
        <Description>
          {description}
        </Description>
        {actions && <Actions>
          <SecondaryButton href="#" data-modal-toggle="aboutISKCON">
            About ISKCON
          </SecondaryButton>
          <SecondaryButton href="#" data-modal-toggle="founderAcharya">Founder Acharya</SecondaryButton>
          {/* <SecondaryButton href="#" data-modal-toggle="philosophy">Philosophy</SecondaryButton> */}
          <SecondaryButtonLink href={Purpose} target="_blank">Purpose</SecondaryButtonLink>
        </Actions>}
      </TextColumn>
      {/* <ImageColumn>
        <ImageContainer>
          <Image src={heroScreenshotImageSrc} />
        </ImageContainer>
      </ImageColumn> */}
    </Container>
  );
};
