import React, { useState } from "react";
import Slider from "react-slick";
import tw from "twin.macro";
import styled from "styled-components";
import { SectionHeading as HeadingBase, Subheading as SubheadingBase } from "components/misc/Headings";
import MainFeature from "components/features/TwoColWithButton.js";

import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons";
import srilaPrabhupada from "images/srila-prabhupada.jpeg";

const PrimaryButton = tw(PrimaryButtonBase)`mt-auto sm:text-lg py-3`;
const Heading = tw(HeadingBase)`text-center text-primary-900 leading-snug text-2xl md:text-3xl p-1`;
const Para = tw.p`text-base leading-relaxed`;
const Subheading = tw(SubheadingBase)`text-center md:text-left`;

const details = {
  subheading: <Subheading>His Divine Grace</Subheading>,
  heading: (<>
    A.C. Bhaktivedanta Swami Prabhupada
  </>),
  description: <>
    <Para>
      His Divine Grace A. C. Bhaktivedanta Swami Prabhupada, Founder-Acharya of International Society for Krishna Consciousness (ISKCON), was born Abhay Charan De, on September 1, 1896, in Calcutta. In 1922 he met His Divine Grace Bhaktisiddanta Sarasvati Thakur, Founder of Gaudiya Math, who requested Abhay to broadcast Vedic knowledge in the English medium. In 1933, at Allahabad, Abhay was formally initiated and made it his life ambition to expound the Vedic conclusion that real freedom means liberation from the miseries of material life : birth, death, old age and disease, a state that can be permanently attained by awakening one’s pure love for God, Krishna-prema or Krishna-bhakti.
    </Para>
    <Para>
      In the ensuing years Abhay Charanaravinda (his initiated name), wrote a commentary on the Bhagavad-gita. In 1944 he started the Back to Godhead magazine, which to this day is being continued by his disciples.
    </Para>
    <Para>
      In recognition of his philosophical knowledge and devotion the Gaudiya Vaishnava Society honored him with the title Bhaktivedanta in 1947. Following his retirement from married life, A.C. Bhaktivedanta traveled to Vrindavan where he lived in the humble surrounding of the Radha Damodar temple. In 1959 he took the sannyasa order of life and, as A.C. Bhaktivedanta Swami, started his work on the multi-volume translation and commentary of the 18,000 verse Srimad Bhagavatam. In 1965, at the age of 69, when ordinary persons are thinking of retirement, he went to the United States to fulfill the mission of his spiritual master and founded ISKCON.
    </Para>
    <Para>
      He brought to the West the divine teachings of Lord Caitanya Mahaprabhu who taught the public glorification of Hare Krishna mantra. Srila Prabhupada, (as he was affectionately called by his followers), taught on a non-sectarian level that every living being is an eternal servant of Lord Krishna with a dormant natural propensity to experience the eternal bliss of pure love of God.
    </Para>
  </>,
  primaryButtonText: "Register",
  imageSrc: srilaPrabhupada,
  imageBorder: true,
  imageDecoratorBlob: true,
  showButton: false,
  imageDecoratorBlob: false
}

export default () => {
  return (
    <div id="founderAcharya" tabindex="-1" class="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-modal md:h-full justify-center items-center hidden" role="dialog" aria-hidden="true">
      <div class="relative p-4 w-full max-w-7xl md:max-w-full h-full md:h-auto">
        <div class="relative bg-white rounded-lg shadow dark:bg-gray-700 h-2/3 lg:h-full">
          <div class="flex justify-between items-center p-4 rounded-t border-b dark:border-gray-600">
            <Heading>
              Founder Acharya
            </Heading>
            <button type="button" class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-toggle="founderAcharya">
              <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
              <span class="sr-only">Close modal</span>
            </button>
          </div>
          <div class="px-6 pb-6 overflow-y-scroll">
            <MainFeature {...details} />
          </div>
          {/* <div class="flex items-end p-6 space-x-2 rounded-b border-t border-gray-200 dark:border-gray-600">
            <PrimaryButton data-modal-toggle="founderAcharya">Close</PrimaryButton>
          </div> */}
        </div>
      </div>
    </div>
  )
}
